<template>
  <div :class="[ 'control', { 'control--is-dark': isDark } ]">
    <div class="control__header">
      <!-- TODO: убрать key для обновления тултипа -->
      <div
        v-if="!isPage"
        :class="[
          'control__tech-name',
          {
            'control__tech-name--is-new': isNew,
            'control__tech-name--is-changed': isChanged
          }
        ]"
        :key="techNameStatus"
        v-tooltip.click="{ value: techNameStatus }"
      >
        <esmp-icon :name="iconName" />
        <span>
          {{ viewName }}
        </span>
      </div>
      <div
        class="control__name"
        :key="label"
        v-tooltip.click="{ value: label }"
      >
        {{ label }}
      </div>
      <div class="control__actions">
        <span
          :class="['control__action', { 'control__action--rotated' : isExpanded }]"
          @click="isExpanded = !isExpanded"
        >
          <esmp-icon name="arrow-down" />
        </span>
        <span
          v-if="!isPage"
          class="control__action"
          @click="copyTechId(element.techId)"
          :key="element.techId"
          v-tooltip.click="{ value: element.techId, options: {icon: 'copy'} }"
        >
          <esmp-icon name="id" />
        </span>
        <span
          v-if="isGroup && !isPage"
          :class="['control__action', { 'control__action--disabled': !connectedFieldListForGroup.length}]"
          :key="connectedFieldListForGroupStr"
          v-tooltip.click="{ value: connectedFieldListForGroupStr }"
        >
          <esmp-icon name="link" />
        </span>
        <span
          v-if="(onlyAdminUsage || hasWorkFlow) && !isPage"
          class="control__action"
          v-tooltip="{ value: 'Привязан workflow/очередь' }"
        >
          <esmp-icon name="workflow" />
        </span>
        <span
          v-if="!isPage"
          class="control__action"
          @click="openSettingsModal"
        >
          <esmp-icon name="edit" />
        </span>
        <span
          v-if="!isPage"
          class="control__action"
          @click="copyControl"
        >
          <esmp-icon name="16-copy" />
        </span>
        <span
          v-if="!isPage"
          class="control__action"
          @click="openConfirmModal"
        >
          <esmp-icon name="trash" />
        </span>
      </div>
    </div>
    <div class="control__body">
      <esmp-collapser :is-expanded.sync="isExpanded" title="">
        <div
          v-if="isPage"
          class="control__body-content"
          v-html="element.content"
        />
        <component
          v-else
          class="collapser-element"
          :is="controlViewName"
          disabled
          :tech-id="element.techId"
          :is-dark="!isDark"
          :is-table="isTable"
          :elements="elements"
          :settings="element.settings"
          @on-update="update"
        />
      </esmp-collapser>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';

import controlsMap from '@/components/service-form/controls.map';
import settingsMap from '@/components/service-form/settings.map';
import getConnectedFieldsForGroup from '@/components/service-form/helpers/getConnectedFieldsForGroup';
import getAllConnectedFields from '@/components/service-form/helpers/getAllConnectedFields';
import Hub from '@/plugins/event-hub';
import { DEFAULT_CONTROL_NAME } from '@/constants/formWorkflow';

const TextView = () => import('@/components/service-form/control-views/TextView.vue');
const InputView = () => import('@/components/service-form/control-views/InputView.vue');
const CmdbView = () => import('@/components/service-form/control-views/CmdbView.vue');
const CheckboxGroupView = () => import('@/components/service-form/control-views/CheckboxGroupView.vue');
const RadioGroupView = () => import('@/components/service-form/control-views/RadioGroupView.vue');
const TableAndGroupView = () => import('@/components/service-form/control-views/TableAndGroupView.vue');
const SelectView = () => import('@/components/service-form/control-views/SelectView.vue');
const TextareaView = () => import('@/components/service-form/control-views/TextareaView.vue');
const CalendarView = () => import('@/components/service-form/control-views/CalendarView.vue');
const GraphCalendarView = () => import('@/components/service-form/control-views/GraphCalendarView.vue');
const UploaderView = () => import('@/components/service-form/control-views/UploaderView.vue');
const ResourceFieldView = () => import('@/components/service-form/control-views/ResourceFieldView.vue');
const SearchView = () => import('@/components/service-form/control-views/SearchView.vue');
const FiasSearchView = () => import('@/components/service-form/control-views/FiasSearchView.vue');
const ContactInfoView = () => import('@/components/service-form/control-views/ContactInfoView.vue');
const NetworkView = () => import('@/components/service-form/control-views/NetworkView.vue');
const CounterView = () => import('@/components/service-form/control-views/CounterView.vue');
const PriorityView = () => import('@/components/service-form/control-views/PriorityView.vue');
const EmployeeReplacementView = () => import('@/components/service-form/control-views/EmployeeReplacementView.vue');
const PlacesAndContractsView = () => import('@/components/service-form/control-views/PlacesAndContractsView.vue');
const DatabaseView = () => import('@/components/service-form/control-views/DatabaseView.vue');
const ScaleView = () => import('@/components/service-form/control-views/ScaleView.vue');
const CalculatedFieldView = () => import('@/components/service-form/control-views/CalculatedFieldView.vue');
const DocumentView = () => import('@/components/service-form/control-views/DocumentView.vue');

export default {
  name: 'ControlItem',
  components: {
    TextView,
    InputView,
    CmdbView,
    CheckboxGroupView,
    RadioGroupView,
    TableAndGroupView,
    SelectView,
    TextareaView,
    CalendarView,
    GraphCalendarView,
    UploaderView,
    ResourceFieldView,
    SearchView,
    FiasSearchView,
    ContactInfoView,
    NetworkView,
    CounterView,
    PriorityView,
    EmployeeReplacementView,
    PlacesAndContractsView,
    DatabaseView,
    ScaleView,
    CalculatedFieldView,
    DocumentView,
  },
  inject: ['globalState'],
  props: {
    element: {
      type: Object,
      required: true,
    },
    dependencies: {
      type: Array,
      default: () => [],
    },
    isDark: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'control',
      validator(value) {
        return ['control', 'page'].includes(value);
      },
    },
  },
  data() {
    return {
      isExpanded: ['group', 'table'].includes(this.element.classType),
      isShowedConfirm: false,
      isUpdated: false,
    };
  },
  computed: {
    elementSettingsAsString() {
      return JSON.stringify(this.element.settings);
    },
    connectedFieldListForGroup() {
      return [...new Set(getConnectedFieldsForGroup(this.element.techId, this.globalState.elements))];
    },
    connectedFieldListForGroupStr() {
      return this.connectedFieldListForGroup.join('<br>');
    },
    connectedFieldListIds() {
      return getAllConnectedFields(this.globalState.elements);
    },
    isTable() {
      return this.element.classType === 'table';
    },
    isGroup() {
      return this.element.classType === 'group';
    },
    onlyAdminUsage() {
      const hasOnlyAdminUsage = this.element?.settings?.find((set) => set.techName === 'onlyAdminUsage');
      return hasOnlyAdminUsage?.value;
    },
    deps() {
      return this.dependencies;
    },
    hasWorkFlow() {
      const list = this.element?.settings?.find((set) => set.techName === 'values');
      if (list?.values?.length) {
        return list.values.some((el) => (el.queueName || el.workflowName));
      }
      if (this.deps?.length) {
        return this.deps.some((dep) => dep.conditions?.some((con) => con.formItemId === this.element.techId));
      }
      return false;
    },
    elements() {
      return this.element.childs || [];
    },
    controlViewName() {
      const controller = controlsMap[this.element.classType];
      return controller ? controller.viewComponentName : null;
    },
    iconName() {
      const controller = controlsMap[this.element.classType];
      return controller ? controller.icon : 'control__nameless';
    },
    viewName() {
      const controller = controlsMap[this.element.classType];
      return controller ? controller.viewName : 'Неизвестный тип';
    },
    label() {
      if (this.isPage) {
        return this.element.isWelcome ? 'Приветствие' : 'Завершение';
      }
      if (this.element.settings && this.element.settings.length) {
        const label = this.element.settings.find((item) => item.techName === 'name')?.value;
        if ((['placesAndContracts', 'text'].includes(this.element.classType))) return '';
        return label ? label.replace(/(<([^>]+)>)/gi, '') : DEFAULT_CONTROL_NAME;
      }
      return '';
    },
    isNew() {
      return !this.element.id;
    },
    isChanged() {
      return !this.isNew ? this.isUpdated : false;
    },
    techNameStatus() {
      if (this.isNew) return 'Новый';
      if (this.isChanged) return 'Изменен';
      return 'Без изменений';
    },
    modalData() {
      return {
        techId: this.element.techId,
        label: this.label,
        onlyAdminUsage: this.onlyAdminUsage,
        hasWorkFlow: this.hasWorkFlow,
        connectedFieldListIds: this.connectedFieldListIds,
      };
    },
    isPage() {
      return this.type === 'page';
    },
  },
  methods: {
    openSettingsModal() {
      Hub.$emit('open-element-settings-modal', this.element);
    },
    copyTechId(id) {
      this.$copyToClipboard(id).then(() => {
        this.$EsmpNotify.$show(`${id} контрола скопирован в буфер обмена`, 'success');
      }).catch(() => {
        this.$EsmpNotify.$show(`${id} контрола не удалось скопировать в буфер обмена`, 'success');
      });
    },
    getSettingComponent(setting) {
      return settingsMap[setting.techName]?.component || 'div';
    },
    update(data) {
      this.$emit('on-update', { id: this.element.techId, data });
    },
    openConfirmModal() {
      Hub.$emit('show-delete-control-confirm-modal', this.modalData);
    },
    copyControl() {
      this.$emit('on-copy', this.element.techId);
    },
  },
  mounted() {
    Hub.$on('delete-control', (id) => {
      this.$emit('on-remove', id);
    });
    Hub.$on('toggle-views', (val) => {
      this.isExpanded = val;
    });
    Hub.$on('on-save-form', () => {
      this.isUpdated = false;
    });
  },
  beforeDestroy() {
    Hub.$off('delete-element');
    Hub.$off('toggle-views');
    Hub.$off('on-save-form');
  },
  watch: {
    elementSettingsAsString(newVal, oldVal) {
      this.isUpdated = !isEqual(newVal, oldVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.control {
  background-color: $color-white;
  margin: 20px 0 0 0;
  cursor: move;
  border-radius: $base-border-radius;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  &--is-dark {
    background-color: $color-grayscale-05;
  }
  &:first-child {
    margin: 0;
  }
  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    border-radius: $base-border-radius $base-border-radius 0 0;
  }
  &__body {
    border-radius: 8px;
    box-shadow: 0 0 1px rgba(28, 41, 61, 0.1), 0 -36px 36px rgba(28, 41, 61, 0.04);
  }
  &-content {
    padding: 10px;
  }
  &__tech-name {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid rgba(16, 24, 40, 0.1);
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #101828;
    span {
      margin-left: 4px;
    }
    &--is-new {
      background-color: $color-yellow-status-op-15;
    }
    &--is-changed {
      background-color: $color-secondary-2-day-op-15;
    }
  }
  &__name {
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #101828;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__actions {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__action {
    transition: transform $base-animation;
    &:hover {
      cursor: pointer;
    }
    & + & {
      margin-left: 4px;
    }
    &--disabled {
      color: $color-grayscale-60;
      pointer-events: none;
      &:hover {
        cursor: default;
      }
    }
    &--rotated {
      transform: rotate(-180deg);
    }
  }
  ::v-deep .esmp-collapser {
    border: none;
    padding: 0;
    background-color: transparent;
    &__heading {
      display: none;
    }
    &__body {
      padding: 0;
    }
  }
}
.collapser-element {
  padding: 10px 20px;
}
.collapser-element.table-view,.collapser-element.group-view {
  padding: 0;
}
</style>
