const getConnectedFieldsForGroup = (id, arr) => {
  const list = [];
  arr.forEach((el) => {
    if (['select', 'checkboxGroup', 'radioGroup', 'cmdb'].includes(el.classType)) {
      let values = el.settings.find((s) => s.techName === 'values')?.values;
      if (el.classType === 'cmdb') {
        const valuesObj = el.settings.find((s) => s.techName === 'cmdbObject')?.value?.configItems;
        if (valuesObj) values = Object.values(valuesObj);
      }
      if (values) {
        values.forEach((val) => {
          if (val.connectedGroups) {
            if (val.connectedGroups.includes(id)) {
              list.push(`${el.settings.find((s) => s.techName === 'name').value} - ${el.techId}`);
            }
          }
        });
      }
    }
    if (['group', 'table'].includes(el.classType)) [].push.apply(list, getConnectedFieldsForGroup(id, el.childs));
  });
  return list;
};

export default getConnectedFieldsForGroup;
