const getAllConnectedFields = (arr) => {
  const list = [];
  arr.forEach((el) => {
    if (['select', 'checkboxGroup', 'radioGroup', 'cmdb'].includes(el.classType)) {
      let values = el.settings.find((s) => s.techName === 'values')?.values;
      if (el.classType === 'cmdb') {
        const valuesObj = el.settings.find((s) => s.techName === 'cmdbObject')?.value?.configItems;
        if (valuesObj) values = Object.values(valuesObj);
      }
      if (values) {
        values.forEach((val) => {
          if (val?.connectedGroups?.length) list.push(el.techId);
        });
      }
    }
    if (['group', 'table'].includes(el.classType)) [].push.apply(list, getAllConnectedFields(el.childs));
  });
  return list;
};

export default getAllConnectedFields;
