const settingsMap = {
  simpleTextValue: {
    component: 's-simple-text-value',
  },
  reportError: {
    component: 's-switch',
  },
  name: {
    component: 's-name',
  },
  required: {
    component: 's-switch',
  },
  isHiddenAfterTicketCreate: {
    component: 's-switch',
  },
  hidden: {
    component: 's-switch',
  },
  hideName: {
    component: 's-switch',
  },
  hideGroupBorder: {
    component: 's-switch',
  },
  isInline: {
    component: 's-switch',
  },
  excludeWeekends: {
    component: 's-switch',
  },
  excludeHolidays: {
    component: 's-exclude-holidays',
  },
  addMoreGroup: {
    component: 's-add-more-group',
  },
  maxLength: {
    component: 's-min-max',
  },
  minLength: {
    component: 's-min-max',
  },
  maskEnabled: {
    component: 's-mask',
  },
  addToDescription: {
    component: 's-add-to-desc',
  },
  onlyAdminUsage: {
    component: 's-switch',
  },
  addToSubject: {
    component: 's-switch',
  },
  hintText: {
    component: 's-hint-text',
  },
  externalSystemField: {
    component: 's-external-system-field',
  },
  values: {
    component: 's-values',
  },
  defaultValue: {
    component: 's-default-value',
  },
  showDateByDefault: {
    component: 's-show-date-by-default',
  },
  datesToShowType: {
    component: 's-dates-to-show-type',
  },
  showTime: {
    component: 's-switch',
  },
  viewMode: {
    component: 's-view-mode',
  },
  controlsDisplayType: {
    component: 's-view-mode',
  },
  excludeMinRelativeDate: {
    component: 's-exclude-min-max-relative-date',
  },
  excludeMaxRelativeDate: {
    component: 's-exclude-min-max-relative-date',
  },
  minRelativeDate: {
    component: 's-exclude-min-max-relative-date',
  },
  maxRelativeDate: {
    component: 's-exclude-min-max-relative-date',
  },
  minRangeDate: {
    component: 's-min-max-range-date',
  },
  maxRangeDate: {
    component: 's-min-max-range-date',
  },
  minDate: {
    component: 's-min-max-date',
  },
  maxDate: {
    component: 's-min-max-date',
  },
  resourceGroup: {
    component: 's-resource-group',
  },
  isShowAccessType: {
    component: 's-switch',
  },
  addResourceOrRoleConfig: {
    component: 's-add-resource-or-role-config',
  },
  showAllCountryFlag: {
    component: 's-switch',
  },
  contactInfoType: {
    component: 's-contact-info-type',
  },
  showEmployee: {
    component: 's-switch',
  },
  showPlacementType: {
    component: 's-switch',
  },
  showPlacementNumber: {
    component: 's-switch',
  },
  roomChoiceTrouble: {
    component: 's-switch',
  },
  addressChoiceTrouble: {
    component: 's-switch',
  },
  notToValidateRelocationData: {
    component: 's-switch',
  },
  replaceDirection: {
    component: 's-replace-direction',
  },
  isUniqueValue: {
    component: 's-switch',
  },
  cmdbObject: {
    component: 's-cmdb-object',
  },
  searchType: {
    component: 's-search-type',
  },
  createApprovement: {
    component: 's-switch',
  },
  isUseHint: {
    component: 's-is-use-hint',
  },
  minValue: {
    component: 's-min-max',
  },
  maxValue: {
    component: 's-min-max',
  },
  isSearch: {
    component: 's-switch',
  },
  databaseObject: {
    component: 's-database',
  },
  scaleValues: {
    component: 's-scale-options',
  },
  startRange: {
    component: 's-input',
  },
  endRange: {
    component: 's-input',
  },
  description: {
    component: 's-hint-text',
  },
  smTechField: {
    component: 's-input',
  },
  tooltipDescription: {
    component: 's-input',
  },
  addToArticle: {
    component: 's-switch',
  },
  calculationFormula: {
    component: 's-calculated-field',
  },
  document: {
    component: 's-document',
  },
  addMoreDocument: {
    component: 's-add-more-document',
  },
  dataFromTicketEnabled: {
    component: 's-form-data',
  },
  decimalNumberPlaces: {
    component: 's-number-input',
  },
  defaultInputValue: {
    component: 's-input',
  },
};

export default settingsMap;
